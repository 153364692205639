// Main content print
@media print {

    .print {
        display: block !important;
    }

    .no-break {
        page-break-inside: avoid;
    }

    .page-break-after,
    .page-break-before {
        width: 100%;
        display: block;
        clear: both;
    }

    .page-break-after {
        page-break-after: always;
    }

    .page-break-before {
        page-break-before: always;
    }

    .content-container {

        &.-section {

            h3 {
                margin-top: 1.25rem;
            }
        }
    }

    .footer {

        .-catalog &,
        .-section ~ & {
            page-break-before: always;
        }
    }

    .footer__copy {
        width: 100%;
        height: 20px;
        margin: 0;
        position: fixed;
        bottom: 0;
        left: 0;
        font-size: 7pt;
    }

    .footer__contact-block {
        width: 100%;
        margin: 1rem 0;
        text-align: center;
    }
}
