// Base variables
//
//      file: base/variables.scss
//
// styleguide 2.1

// Paths
//
// **`$base_font-path`**: ../fonts
//
// **`$base_image-path`**: ../images
//
// **`$base_data-uri-path`**: ../images/data-uri
//
//  styleguide 2.1.1
$base_font-path: '../fonts' !default;
$base_image-path: '../images' !default;
$base_data-uri-path: '../images/data-uri' !default;

// Layout
//
// **`$base_container-name`**: 'content-container'
//
//  styleguide 2.1.2
$base_container-name: 'content-container';

// Grid
//
//      file: base/grid.scss
//
// **`$grid_columns`**: 12;
//
// **`$grid_gutter`**: 10px;
//
// **`$grid_row-name`**: 'row'
//
// **`$grid_column-name`**: 'block'
//
// **`$grid:`**:
//
//         xs: (
//             breakpoint: 300px,
//             container: 288px
//         ),
//         sm: (
//             breakpoint: 600px,
//             container: 576px
//         )
//         md: (
//             breakpoint: 900px,
//             container: 864px
//         ),
//         lg: (
//             breakpoint: 1200px,
//             container: 1152px
//         ),
//         xl: (
//             breakpoint: 1500px,
//             container: 1440px
//         ),
//         xxl: (
//             breakpoint: 1800px,
//             container: 1600px
//         )
//
// styleguide 3.1

// Flex grid
//
// <p class="ciu_embed" data-feature="flexbox" data-periods="future_1,current,past_1,past_2,past_3">
//     <a href="http://caniuse.com/#feat=flexbox">Can I Use flexbox?</a> Data on support for the flexbox feature across the major browsers from caniuse.com.
// </p>
//
// styleguide 3.2
$grid_columns: 12 !default;
$grid_gutter: 10px !default;

$grid_row-name: 'row' !default;
$grid_column-name: 'col' !default;

$grid: (
    xs: (
        breakpoint: 300px,
        container: 288px
    ),
    sm: (
        breakpoint: 600px,
        container: 576px
    ),
    md: (
        breakpoint: 900px,
        container: 864px
    ),
    lg: (
        breakpoint: 1200px,
        container: 1152px
    ),
    xl: (
        breakpoint: 1500px,
        container: 1440px
    ),
    xxl: (
        breakpoint: 1800px,
        container: 1600px
    )
) !default;

// Colors
//
// **`$base_background`**: #fff
//
// **`$color-green`**: #8da709
//
// **`$color-blue`**: #379cfa
//
// **`$color-yellow`**: #eeb624
//
// **`$color-red`**: #c24444
//
// **`$color-white`**: #fff
//
// **`$color-black`**: #000
//
// **`$color-black-10`**: #e6e6e6
//
// **`$color-black-20`**: #cccccc
//
// **`$color-black-30`**: #b3b3b3
//
// **`$color-black-40`**: #999999
//
// **`$color-black-50`**: #808080
//
// **`$color-black-60`**: #666666
//
// **`$color-black-70`**: #4d4d4d
//
// **`$color-black-80`**: #333333
//
// **`$color-black-90`**: #1a1a1a
//
// **`$color-light-gray`**: #cccccc
//
// **`$color-gray`**: #808080
//
// **`$color-dark-gray`**: #333333
//
// **`$base_variants`**: (success: $color-green, info: $color-blue, warning: $color-yellow, danger: $color-red)
//
// styleguide 2.1.3
$base_background: #fff !default;
$base_color: #000 !default;
$color-green: #8da709 !default;
$color-blue: #379cfa !default;
$color-yellow: #eeb624 !default;
$color-red: #c24444 !default;
$color-black: #000 !default;
$color-white: #fff !default;
$color-black-10: lighten(#000,90%) !default;
$color-black-20: lighten(#000,80%) !default;
$color-black-30: lighten(#000,70%) !default;
$color-black-40: lighten(#000,60%) !default;
$color-black-50: lighten(#000,50%) !default;
$color-black-60: lighten(#000,40%) !default;
$color-black-70: lighten(#000,30%) !default;
$color-black-80: lighten(#000,20%) !default;
$color-black-90: lighten(#000,10%) !default;
$color-light-gray: $color-black-20 !default;
$color-gray: $color-black-50 !default;
$color-dark-gray: $color-black-80 !default;
$base_variants: (
    success: $color-green,
    info: $color-blue,
    warning: $color-yellow,
    danger: $color-red
) !default;

// Typography
//
// **`$base_font-size`**: 12px
//
// **`$base_line-height`**: 1.25 -> 15px
//
// styleguide 2.1.4
$base_font-size: 12px !default;
$base_line-height: 1.25 !default;

// Layout
//
// **`$base_border-radius`**: 4px
//
// styleguide  2.1.5
$base_border-radius: 4px !default;

// Transition
//
// **`$base_transition-duration-in`**: .5s
//
// **`$base_transition-duration-out`**: .05s
//
// **`$base_transition-timing`**: ease-in-out
//
// <p class="ciu_embed" data-feature="css-transitions" data-periods="future_1,current,past_1,past_2,past_3">
//     <a href="http://caniuse.com/#feat=css-transitions">Can I Use css-transitions?</a> Data on support for the css-transitions feature across the major browsers from caniuse.com.
// </p>
//
// <div class="row">
//     <table class="col-xs-12 -rounded -hovered">
//         <thead>
//             <tr>
//                 <th>**Property name**</th>
//                 <th>**Type**</th>
//             </tr>
//         </thead>
//         <tbody>
//             <tr>
//                <td>**`background-color`**</td>
//                <td>color</td>
//             </tr>
//             <tr>
//                <td>**`background-image`**</td>
//                <td>only gradients</td>
//             </tr>
//             <tr>
//                <td>**`background-position`**</td>
//                <td>percentage, length</td>
//             </tr>
//             <tr>
//                <td>**`border-bottom-color`**</td>
//                <td>color</td>
//             </tr>
//             <tr>
//                <td>**`border-bottom-width`**</td>
//                <td>length</td>
//             </tr>
//             <tr>
//                <td>**`border-color`**</td>
//                <td>color</td>
//             </tr>
//             <tr>
//                <td>**`border-left-color`**</td>
//                <td>color</td>
//             </tr>
//             <tr>
//                <td>**`border-left-width`**</td>
//                <td>length</td>
//             </tr>
//             <tr>
//                <td>**`border-right-color`**</td>
//                <td>color</td>
//             </tr>
//             <tr>
//                <td>**`border-right-width`**</td>
//                <td>length</td>
//             </tr>
//             <tr>
//                <td>**`border-spacing`**</td>
//                <td>length</td>
//             </tr>
//             <tr>
//                <td>**`border-top-color`**</td>
//                <td>color</td>
//             </tr>
//             <tr>
//                <td>**`border-top-width`**</td>
//                <td>length</td>
//             </tr>
//             <tr>
//                <td>**`border-width`**</td>
//                <td>length</td>
//             </tr>
//             <tr>
//                <td>**`bottom`**</td>
//                <td>length, percentage</td>
//             </tr>
//             <tr>
//                <td>**`color`**</td>
//                <td>color</td>
//             </tr>
//             <tr>
//                <td>**`crop`**</td>
//                <td>rectangle</td>
//             </tr>
//             <tr>
//                <td>**`font-size`**</td>
//                <td>length, percentage</td>
//             </tr>
//             <tr>
//                <td>**`font-weight`**</td>
//                <td>number</td>
//             </tr>
//             <tr>
//                <td>**`grid-*`**</td>
//                <td>various</td>
//             </tr>
//             <tr>
//                <td>**`height`**</td>
//                <td>length, percentage</td>
//             </tr>
//             <tr>
//                <td>**`left`**</td>
//                <td>length, percentage</td>
//             </tr>
//             <tr>
//                <td>**`letter-spacing`**</td>
//                <td>length</td>
//             </tr>
//             <tr>
//                <td>**`line-height`**</td>
//                <td>number, length, percentage</td>
//             </tr>
//             <tr>
//                <td>**`margin-bottom`**</td>
//                <td>length</td>
//             </tr>
//             <tr>
//                <td>**`margin-left`**</td>
//                <td>length</td>
//             </tr>
//             <tr>
//                <td>**`margin-right`**</td>
//                <td>length</td>
//             </tr>
//             <tr>
//                <td>**`margin-top`**</td>
//                <td>length</td>
//             </tr>
//             <tr>
//                <td>**`max-height`**</td>
//                <td>length, percentage</td>
//             </tr>
//             <tr>
//                <td>**`max-width`**</td>
//                <td>length, percentage</td>
//             </tr>
//             <tr>
//                <td>**`min-height`**</td>
//                <td>length, percentage</td>
//             </tr>
//             <tr>
//                <td>**`min-width`**</td>
//                <td>length, percentage</td>
//             </tr>
//             <tr>
//                <td>**`opacity`**</td>
//                <td>number</td>
//             </tr>
//             <tr>
//                <td>**`outline-color`**</td>
//                <td>color</td>
//             </tr>
//             <tr>
//                <td>**`outline-offset`**</td>
//                <td>integer</td>
//             </tr>
//             <tr>
//                <td>**`outline-width`**</td>
//                <td>length</td>
//             </tr>
//             <tr>
//                <td>**`padding-bottom`**</td>
//                <td>length</td>
//             </tr>
//             <tr>
//                <td>**`padding-left`**</td>
//                <td>length</td>
//             </tr>
//             <tr>
//                <td>**`padding-right`**</td>
//                <td>length</td>
//             </tr>
//             <tr>
//                <td>**`padding-top`**</td>
//                <td>length</td>
//             </tr>
//             <tr>
//                <td>**`right`**</td>
//                <td>length, percentage</td>
//             </tr>
//             <tr>
//                <td>**`text-indent`**</td>
//                <td>length, percentage</td>
//             </tr>
//             <tr>
//                <td>**`text-shadow`**</td>
//                <td>shadow</td>
//             </tr>
//             <tr>
//                <td>**`top`**</td>
//                <td>length, percentage</td>
//             </tr>
//             <tr>
//                <td>**`vertical-align`**</td>
//                <td>keywords, length, percentage</td>
//             </tr>
//             <tr>
//                <td>**`visibility`**</td>
//                <td>visibility</td>
//             </tr>
//             <tr>
//                <td>**`width`**</td>
//                <td>length, percentage</td>
//             </tr>
//             <tr>
//                <td>**`word-spacing`**</td>
//                <td>length, percentage</td>
//             </tr>
//             <tr>
//                <td>**`z-index`**</td>
//                <td>integer</td>
//             </tr>
//             <tr>
//                <td>**`zoom`**</td>
//                <td>number</td>
//             </tr>
//         </tbody>
//     </table>
// </div>
//
// styleguide 2.1.6
$base_transition-duration-in: .5s !default;
$base_transition-duration-out: .05s !default;
$base_transition-timing: ease-in-out !default;

// Easing
//
// Cubic bezier easing functions of Matthew Wagerfield
//
// **`$ease-in-cubic`**
// **`$ease-out-cubic`**
// **`$ease-in-out-cubic`**
//
// **`$ease-in-circ`**
// **`$ease-out-circ`**
// **`$ease-in-out-circ`**
//
// **`$ease-in-expo`**
// **`$ease-out-expo`**
// **`$ease-in-out-expo`**
//
// **`$ease-in-quad`**
// **`$ease-out-quad`**
// **`$ease-in-out-quad`**
//
// **`$ease-in-quart`**
// **`$ease-out-quart`**
// **`$ease-in-out-quart`**
//
// **`$ease-in-quint`**
// **`$ease-out-quint`**
// **`$ease-in-out-quint`**
//
// **`$ease-in-sine`**
// **`$ease-out-sine`**
// **`$ease-in-out-sine`**
//
// **`$ease-in-back`**
// **`$ease-out-back`**
// **`$ease-in-out-back`**
//
// styleguide 2.1.7

// Copyright(C) 2012 Matthew Wagerfield
//
// Permission is hereby granted,free of charge,to any
// person obtaining a copy of this software and associated
// documentation files(the "Software"),to deal in the
// Software without restriction,including without limitation
// the rights to use,copy,modify,merge,publish,distribute,
// sublicense,and/or sell copies of the Software,and to
// permit persons to whom the Software is furnished to do
// so,subject to the following conditions:
//
// The above copyright notice and this permission notice
// shall be included in all copies or substantial portions
// of the Software.
//
// THE SOFTWARE IS PROVIDED "AS IS",WITHOUT WARRANTY
// OF ANY KIND,EXPRESS OR IMPLIED,INCLUDING BUT NOT
// LIMITED TO THE WARRANTIES OF MERCHANTABILITY,FITNESS
// FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO
// EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE
// FOR ANY CLAIM,DAMAGES OR OTHER LIABILITY,WHETHER IN
// AN ACTION OF CONTRACT,TORT OR OTHERWISE,ARISING FROM,
// OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE
// OR OTHER DEALINGS IN THE SOFTWARE.

$ease-in-cubic: cubic-bezier(.55,.055,.675,.19) !default;
$ease-out-cubic: cubic-bezier(.215,.61,.355,1) !default;
$ease-in-out-cubic: cubic-bezier(.645,.045,.355,1) !default;

$ease-in-circ: cubic-bezier(.6,.04,.98,.335) !default;
$ease-out-circ: cubic-bezier(.075,.82,.165,1) !default;
$ease-in-out-circ: cubic-bezier(.785,.135,.15,.86) !default;

$ease-in-expo: cubic-bezier(.95,.05,.795,.035) !default;
$ease-out-expo: cubic-bezier(.19,1,.22,1) !default;
$ease-in-out-expo: cubic-bezier(1,0,0,1) !default;

$ease-in-quad: cubic-bezier(.55,.085,.68,.53) !default;
$ease-out-quad: cubic-bezier(.25,.46,.45,.94) !default;
$ease-in-out-quad: cubic-bezier(.455,.03,.515,.955) !default;

$ease-in-quart: cubic-bezier(.895,.03,.685,.22) !default;
$ease-out-quart: cubic-bezier(.165,.84,.44,1) !default;
$ease-in-out-quart: cubic-bezier(.77,0,.175,1) !default;

$ease-in-quint: cubic-bezier(.755,.05,.855,.06) !default;
$ease-out-quint: cubic-bezier(.23,1,.32,1) !default;
$ease-in-out-quint: cubic-bezier(.86,0,.07,1) !default;

$ease-in-sine: cubic-bezier(.47,0,.745,.715) !default;
$ease-out-sine: cubic-bezier(.39,.575,.565,1) !default;
$ease-in-out-sine: cubic-bezier(.445,.05,.55,.95) !default;

$ease-in-back: cubic-bezier(.6,-.28,.735,.045) !default;
$ease-out-back: cubic-bezier(.175, .885,.32,1.275) !default;
$ease-in-out-back: cubic-bezier(.68,-.55,.265,1.55) !default;
