// Catalog
@media print {

    .catalog {

        .car {
            @include clearfix;
            width: 100% !important;
            page-break-after: always;
        }

        .car__heading {
            text-align: center;
        }

        .car__description {
            text-align: left;
        }

        .car__description-value {
            font-weight: bold;
        }

        .car__image {
            margin: 10pt 0;

            img {
                margin: 0 auto;
            }
        }

        .car__heading-text {
            font-size: 16pt;
        }

        .car__price {
            float: right;
            font-size: 18pt;
        }
    }
}
