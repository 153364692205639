// Normalize
//
//      file: core/normalize.scss
//
// Normalize elements
//
// Styleguide 1.2

* {box-sizing: border-box;}

*::before,
*::after {box-sizing: border-box;}

html,
body {height: 100%;}

body {overflow-y: scroll;}

[hidden],
template {display: none;}

a {
    background-color: transparent;
    text-decoration: underline;

    &:focus {text-decoration: none;}

    &:hover {
        text-decoration: none;
        outline: 0;
    }

    &:active {outline: 0;}
}

img {-ms-interpolation-mode: bicubic;}

audio:not([controls]) {
    height: 0;
    display: none;
}

svg:not(:root) {overflow: hidden;}

input,
textarea,
select {
    font-family: inherit;
    font-size: inherit;
}

button,
input,
select,
textarea {
    vertical-align: middle;
    border: none;
}

textarea {
    overflow: auto;
    vertical-align: top;
}

button,
input {line-height: normal;}

button,
select {text-transform: none;}

// CSS appearance is not supported by  IE (9,10,11), Chrome (48), iOS Safari (9.0-9.2), Chrome for Android (47), UC Browser for Android (9.9)
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    cursor: pointer;
    appearance: button;
}

button[disabled],
input[disabled] {cursor: default;}

input[readonly] {cursor: not-allowed;}

input[type="search"] {appearance: textfield;}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="submit"] {appearance: none;}

button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;

    th {text-align: left;}
}

strong {font-weight: bold;}

sup {
    vertical-align: super;
    font-size: 75%;
}

sub {
    vertical-align: sub;
    font-size: 75%;
}
