// Car detail print
@media print {

    .detail {

        h3 {
            margin-top: .75rem;
        }

        .slideshow-car {
            position: relative;
        }

        .slideshow__item {

            img {
                margin: 0 auto;
            }
        }

        .car {
            width: 100% !important;
        }

        .car__section {
            margin-bottom: .75rem;
        }

        .car__heading,
        .car__description {
            text-align: left;
        }

        .car__image {
            margin: 10pt 0;
        }

        .car__price-content {
            margin: 20pt 0;
            text-align: center;

            strong {
                margin-left: 20pt;
                font-size: 24pt;
            }
        }

        .car__vendor-location {
            position: absolute;
            top: 20px;
            left: 50%;
            z-index: 6;
            transform: translateX(-50%);
        }

        .car__vendor {
            @include clearfix;
            width: 100%;
            margin-top: 30pt;
            page-break-after: always;
        }

        .car__vendor-image,
        .car__vendor-data {
            float: left;
        }

        .car__vendor-image {
            max-width: 150pt;
            margin-right: 20pt;
        }

        .car__description-title,
        .car__description-value {
            width: 49%;
            display: inline-block;
            border-bottom: 1px solid #999;
        }

        .car__claim {
            font-size: 12pt;
            font-style: italic;
        }
    }

    .detail__price {
        width: 49%;
        display: inline-block;

        &.-bordered {
            border-bottom: 1px solid #999;
        }
    }
}
