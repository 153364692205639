// Print
//
//      file: media/print.scss
//
// Inspired by print styles from BafS/Gutenberg repository, version **`0.3.0-beta`**
//
// If **$print_trigger** is false, use it in separated stylesheet file, otherwise it's inside **@media print** block
//
// **`src`**: https://github.com/BafS/Gutenberg
//
// Media **`print`** styles
//
// styleguide 7.1

// Default variables
$print_offset: 1.5rem;
$print_page-padding-horizontal: 1.8cm;
$print_page-padding-vertical: 2.54cm;
$print_background: #fff !default;
$print_color: #000 !default;
$print_font-size: 16px !default;
$print_font-size-h1: 2.5rem !default;
$print_font-size-h2: 2rem !default;
$print_font-size-h3: 1.75rem !default;
$print_font-size-h4: 1.5rem !default;
$print_font-size-h5: 1.25rem !default;
$print_font-size-h6: 1rem !default;
$print_line-height: 1.5 !default;

@mixin print() {

    @if $print_trigger == true {

        // Global
        * {
            box-sizing: border-box;

            &,
            &::before,
            &::after {
                background: transparent !important;
                text-shadow: none !important;
                box-shadow: none !important;
            }
        }

        // Remove shadow and background
        p,
        div,
        blockquote,
        li {

            &::first-letter,
            &::first-line {
                background: transparent !important;
                text-shadow: none !important;
                box-shadow: none !important;
            }
        }

        html {
            margin: 0;
            padding: 0;
            font-size: $print_font-size;
        }

        body {
            @include sans-serif;
            margin: 0 auto;
            padding: $print_page-padding-vertical;
            background: $print_background !important;
            color: $print_color !important;
            font-size: 1rem;
            line-height: $print_line-height;
            text-align: justify;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-rendering: optimizeLegibility;
        }

        p,
        blockquote,
        table,
        ul,
        ol,
        dl {
            margin-top: 0;
            margin-bottom: $print_offset;
        }

        p,
        ul,
        ol {

            &:last-child {margin-bottom: 0;}
        }

        // Headers
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include sans-serif;
            margin-top: 0;
            margin-bottom: $print_offset / 2;
            color: $print_color;
            line-height: 1.2;
        }

        h1 {
            font-size: $print_font-size-h1;
            text-align: center;
        }

        h2 {font-size: $print_font-size-h2;}
        h3 {font-size: $print_font-size-h3;}
        h4 {font-size: $print_font-size-h4;}
        h5 {font-size: $print_font-size-h5;}
        h6 {font-size: $print_font-size-h6;}

        // Links
        a {

            &,
            &:visited {
                color: $print_color;
                text-decoration: underline;
                word-wrap: break-word;
            }

            &[href^="http://"],
            &[href^="https://"],
            &[href^="ftp://"],
            &[href^="mailto:"] {

                &::after {
                    content: ' (" attr(href) ")';
                    font-size: 80%;
                }
            }
        }

        // Table
        table {border-collapse: collapse;}

        thead {
            display: table-header-group;
            border-bottom: 3px double $print_color;
        }

        table,
        th,
        td {border-bottom: 1px solid $print_color;}

        td,
        th {padding: ($print_font-size / 2) $print_font-size;}

        // Code, Pre
        code,
        pre,
        kbd {
            @include monospace;
            font-size: 85%;
            border: 1px solid $print_color;
        }

        code,
        kbd {padding: 3px;}

        pre {
            margin-bottom: $print_offset;
            padding: 10px 12px;
            border: 1px solid $print_color;

            code,
            kbd {border: none;}
        }

        // Others
        blockquote {
            margin-left: $print_offset;
            padding-left: 1rem;
            font-variant: small-caps;
            border-left: 1px dashed $print_color;

            &:first-child {margin-top: 0;}

            p {

                &::first-letter {font-weight: bold;}
            }

            p,
            ul,
            ol {

                &:last-child {margin-bottom: 0;}
            }

            footer {
                display: block;
                font-size: 80%;
            }
        }

        img {
            max-width: 100% !important;
            display: block;
            border: none;
        }

        hr {
            height: 0;
            margin: $print_offset * $print_line-height 0;
            padding: 0;
            border: none;
            border-bottom: 2px solid $color-light-gray;
        }

        dt {font-weight: bold;}

        dd {
            margin: 0;
            margin-bottom: $print_offset / 2;
        }

        abbr[title],
        acronym[title] {
            text-decoration: none;
            border: none;

            &::after {content: ' (" attr(title) ")';}
        }

        table,
        blockquote,
        pre,
        code,
        figure,
        li,
        hr,
        ul,
        ol,
        a,
        tr {page-break-inside: avoid;}

        // Orphans and widows
        h2,
        h3,
        h4,
        p,
        a {
            orphans: 3;
            widows: 3;
        }

        // Avoid breaking inside or just after titles
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            page-break-after: avoid;
            page-break-inside: avoid;
        }

        // Avoid breaking before a paragraph if header before
        h1 + p,
        h2 + p,
        h3 + p {page-break-before: avoid;}

        // Avoid to break inside an image
        img {
            page-break-before: auto;
            page-break-after: auto;
            page-break-inside: avoid;
        }

        pre {
            white-space: pre-wrap !important;
            word-wrap: break-word;
        }

        .no-print {display: none !important;}
    }
}

@if $print_trigger == true {
    @media print {
        @include print
    }
} @else {
@include print;
}
