// Helpers
//
//      file: base/helpers.scss
//
// Helpers can be used directly on `html` elements or can be extended in styles (@extend).
//
// **`._clearfix`** - clearfix
//
// ** Display**
// ***
// **`._hide`** - hide element (display: none)
//
// **`._show`** - show element (display: block)
//
// **`._flex`** - flex display
//
// **`._in`** - inline display
//
// **`._inb`** - inline-block display
//
// **Visibility**
// ***
// **`.-vis`** - visible element
//
// **`.-invis`** - invisible element
//
// **Float**
// ***
// **`.-fl`** - left floating
//
// **`._fr`** - right floating
//
// **`._fn`** - reset floating
//
// **Clear**
// ***
// **`._cl`** - left clearing
//
// **`._cr`** - right clearing
//
// **`._cb`** -  both clearing
//
// **`._cn`** - reset clearing
//
// **Text align**
// ***
// **`._al`** - left text align
//
// **`._ar`** - right text align
//
// **`._ac`** - center text align
//
// **`._aj`** - justify text align
//
// **Colors**
// ***
// **`._color-green`** - color green
//
// **`._color-blue`** - color blue
//
// **`._color-yellow`** - color yellow
//
// **`._color-red`** - color red
//
// **`._color-white`** - color white
//
// **`._color-black`** - color black
//
// **Backgrounds**
// ***
// **`._background-green`** - background green
//
// **`._background-blue`** - background blue
//
// **`._background-yellow`** - background yellow
//
// **`._background-red`** - background red
//
// **`._background-white`** - background white
//
// **`._background-black`** - background black
//
// styleguide 2.4

// Clearfix
._clearfix {@include clearfix;}

// Display
._hide {display: none !important;}
._show {display: block !important;}
// CSS flexbox is not supported by IE (9,10,11), UC Browser for Android (9.9)
._flex {display: flex !important;}
._in {display: inline !important;}
._inb {display: inline-block !important;}
._vis {visibility: visible !important;}
._invis {visibility: hidden !important;}

// Float
._fl {float: left !important;}
._fr {float: right !important;}
._fn {float: none !important;}

// Clear
._cl {clear: left !important;}
._cr {clear: right !important;}
._cb {clear: both !important;}
._cn {clear: none !important;}

// Text
._al {text-align: left !important;}
._ar {text-align: right !important;}
._ac {text-align: center !important;}
._aj {text-align: justify !important;}

// Colors
._color-green {@extend %color-green;}
._color-blue {@extend %color-blue;}
._color-yellow {@extend %color-yellow;}
._color-red {@extend %color-red;}
._color-white {@extend %color-white;}
._color-black {@extend %color-black;}
%color-green {color: $color-green !important;}
%color-blue {color: $color-blue !important;}
%color-yellow {color: $color-yellow !important;}
%color-red {color: $color-red !important;}
%color-white {color: $color-white !important;}
%color-black {color: $color-black !important;}

// Backgrounds
._background-green {@extend %background-green;}
._background-blue {@extend %background-blue;}
._background-yellow {@extend %background-yellow;}
._background-red {@extend %background-red;}
._background-white {@extend %background-white;}
._background-black {@extend %background-black;}
%background-green {background-color: $color-green !important;}
%background-blue {background-color: $color-blue !important;}
%background-yellow {background-color: $color-yellow !important;}
%background-red {background-color: $color-red !important;}
%background-white {background-color: $color-white !important;}
%background-black {background-color: $color-black !important;}
