// Typography
//
//      file: base/typography.scss
//
// **Sans serif fonts**
// ***
// **`$default`**: default sans serif font -> `Arial`
//
// **`use`**: @include sans-serif;
//
// **Serif fonts**
// ***
// **`$default`**: default serif font -> `Georgia`
//
// **`use`**: @include serif;
//
// **Monospace fonts**
// ***
// **`$default`**: default monospace font -> `Monaco`
//
// **`use`**: @include monospace;
//
// **Font face**
// ***
// **`$name`**: imported font face name
//
// **`$path`**: path to webfonts, **`default`**: $base_font-path
//
// **`use`**: @include font-face('font');
//
// <p class="ciu_embed" data-feature="fontface" data-periods="future_1,current,past_1,past_2,past_3">
//     <a href="http://caniuse.com/#feat=fontface">Can I Use fontface?</a> Data on support for the fontface feature across the major browsers from caniuse.com.
// </p>
//
// Styleguide 2.5


// Sans serif fonts
@mixin sans-serif($default: Arial) {
    font-family: quote($default),Tahoma,Nimbus Sans L,Free Sans,sans-serif;
}

// Serif fonts
@mixin serif($default: Georgia) {
    font-family: quote($default),Times New Roman,Times,Free Serif,Century Schoolbook L serif;
}

// Monospace fonts
@mixin monospace($default: Monaco) {
    font-family: quote($default),Courier New,Nimbus Mono L,Free Mono,monospace;
}

// Font face
@mixin font-face($name, $path: $base_font-path) {
    $font-path: $path + '/' + $name;

    @font-face {
        font-family: quote($name);
        src: url($font-path + '.eot');
        src: url($font-path + '.eot?#iefix') format('embedded-opentype'),
             url($font-path + '.woff2') format('woff2'),
             url($font-path + '.woff') format('woff'),
             url($font-path + '.ttf') format('truetype'),
             url($font-path + '.svg#' + $name) format('svg');
    }
}
