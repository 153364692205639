html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
button,
input,
select,
textarea,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  background: transparent;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline;
  outline: 0;
  border: none; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

audio,
canvas,
video {
  display: inline-block; }

body {
  line-height: 1; }

html {
  text-size-adjust: 100%; }

ol,
ul {
  list-style: none;
  list-style-image: none; }

* {
  box-sizing: border-box; }

*::before,
*::after {
  box-sizing: border-box; }

html,
body {
  height: 100%; }

body {
  overflow-y: scroll; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent;
  text-decoration: underline; }
  a:focus {
    text-decoration: none; }
  a:hover {
    text-decoration: none;
    outline: 0; }
  a:active {
    outline: 0; }

img {
  -ms-interpolation-mode: bicubic; }

audio:not([controls]) {
  height: 0;
  display: none; }

svg:not(:root) {
  overflow: hidden; }

input,
textarea,
select {
  font-family: inherit;
  font-size: inherit; }

button,
input,
select,
textarea {
  vertical-align: middle;
  border: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

button,
input {
  line-height: normal; }

button,
select {
  text-transform: none; }

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  appearance: button; }

button[disabled],
input[disabled] {
  cursor: default; }

input[readonly] {
  cursor: not-allowed; }

input[type="search"] {
  appearance: textfield; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="submit"] {
  appearance: none; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }
  table th {
    text-align: left; }

strong {
  font-weight: bold; }

sup {
  vertical-align: super;
  font-size: 75%; }

sub {
  vertical-align: sub;
  font-size: 75%; }

._clearfix::before, ._clearfix::after {
  display: table;
  content: ''; }

._clearfix::after {
  clear: both; }

._hide {
  display: none !important; }

._show {
  display: block !important; }

._flex {
  display: flex !important; }

._in {
  display: inline !important; }

._inb {
  display: inline-block !important; }

._vis {
  visibility: visible !important; }

._invis {
  visibility: hidden !important; }

._fl {
  float: left !important; }

._fr {
  float: right !important; }

._fn {
  float: none !important; }

._cl {
  clear: left !important; }

._cr {
  clear: right !important; }

._cb {
  clear: both !important; }

._cn {
  clear: none !important; }

._al {
  text-align: left !important; }

._ar {
  text-align: right !important; }

._ac {
  text-align: center !important; }

._aj {
  text-align: justify !important; }

._color-green {
  color: #8da709 !important; }

._color-blue {
  color: #379cfa !important; }

._color-yellow {
  color: #eeb624 !important; }

._color-red {
  color: #c24444 !important; }

._color-white {
  color: #fff !important; }

._color-black {
  color: #000 !important; }

._background-green {
  background-color: #8da709 !important; }

._background-blue {
  background-color: #379cfa !important; }

._background-yellow {
  background-color: #eeb624 !important; }

._background-red {
  background-color: #c24444 !important; }

._background-white {
  background-color: #fff !important; }

._background-black {
  background-color: #000 !important; }

@media print {
  * {
    box-sizing: border-box; }
    *, *::before, *::after {
      background: transparent !important;
      text-shadow: none !important;
      box-shadow: none !important; }
  p::first-letter, p::first-line,
  div::first-letter,
  div::first-line,
  blockquote::first-letter,
  blockquote::first-line,
  li::first-letter,
  li::first-line {
    background: transparent !important;
    text-shadow: none !important;
    box-shadow: none !important; }
  html {
    margin: 0;
    padding: 0;
    font-size: 16px; }
  body {
    font-family: "Arial", Tahoma, Nimbus Sans L, Free Sans, sans-serif;
    margin: 0 auto;
    padding: 2.54cm;
    background: #fff !important;
    color: #000 !important;
    font-size: 1rem;
    line-height: 1.5;
    text-align: justify;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility; }
  p,
  blockquote,
  table,
  ul,
  ol,
  dl {
    margin-top: 0;
    margin-bottom: 1.5rem; }
  p:last-child,
  ul:last-child,
  ol:last-child {
    margin-bottom: 0; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Arial", Tahoma, Nimbus Sans L, Free Sans, sans-serif;
    margin-top: 0;
    margin-bottom: 0.75rem;
    color: #000;
    line-height: 1.2; }
  h1 {
    font-size: 2.5rem;
    text-align: center; }
  h2 {
    font-size: 2rem; }
  h3 {
    font-size: 1.75rem; }
  h4 {
    font-size: 1.5rem; }
  h5 {
    font-size: 1.25rem; }
  h6 {
    font-size: 1rem; }
  a, a:visited {
    color: #000;
    text-decoration: underline;
    word-wrap: break-word; }
  a[href^="http://"]::after, a[href^="https://"]::after, a[href^="ftp://"]::after, a[href^="mailto:"]::after {
    content: ' (" attr(href) ")';
    font-size: 80%; }
  table {
    border-collapse: collapse; }
  thead {
    display: table-header-group;
    border-bottom: 3px double #000; }
  table,
  th,
  td {
    border-bottom: 1px solid #000; }
  td,
  th {
    padding: 8px 16px; }
  code,
  pre,
  kbd {
    font-family: "Monaco", Courier New, Nimbus Mono L, Free Mono, monospace;
    font-size: 85%;
    border: 1px solid #000; }
  code,
  kbd {
    padding: 3px; }
  pre {
    margin-bottom: 1.5rem;
    padding: 10px 12px;
    border: 1px solid #000; }
    pre code,
    pre kbd {
      border: none; }
  blockquote {
    margin-left: 1.5rem;
    padding-left: 1rem;
    font-variant: small-caps;
    border-left: 1px dashed #000; }
    blockquote:first-child {
      margin-top: 0; }
    blockquote p::first-letter {
      font-weight: bold; }
    blockquote p:last-child,
    blockquote ul:last-child,
    blockquote ol:last-child {
      margin-bottom: 0; }
    blockquote footer {
      display: block;
      font-size: 80%; }
  img {
    max-width: 100% !important;
    display: block;
    border: none; }
  hr {
    height: 0;
    margin: 2.25rem 0;
    padding: 0;
    border: none;
    border-bottom: 2px solid #cccccc; }
  dt {
    font-weight: bold; }
  dd {
    margin: 0;
    margin-bottom: 0.75rem; }
  abbr[title],
  acronym[title] {
    text-decoration: none;
    border: none; }
    abbr[title]::after,
    acronym[title]::after {
      content: ' (" attr(title) ")'; }
  table,
  blockquote,
  pre,
  code,
  figure,
  li,
  hr,
  ul,
  ol,
  a,
  tr {
    page-break-inside: avoid; }
  h2,
  h3,
  h4,
  p,
  a {
    orphans: 3;
    widows: 3; }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
    page-break-inside: avoid; }
  h1 + p,
  h2 + p,
  h3 + p {
    page-break-before: avoid; }
  img {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid; }
  pre {
    white-space: pre-wrap !important;
    word-wrap: break-word; }
  .no-print {
    display: none !important; } }

@media print {
  .print {
    display: block !important; }
  .no-break {
    page-break-inside: avoid; }
  .page-break-after,
  .page-break-before {
    width: 100%;
    display: block;
    clear: both; }
  .page-break-after {
    page-break-after: always; }
  .page-break-before {
    page-break-before: always; }
  .content-container.-section h3 {
    margin-top: 1.25rem; }
  .-catalog .footer,
  .-section ~ .footer {
    page-break-before: always; }
  .footer__copy {
    width: 100%;
    height: 20px;
    margin: 0;
    position: fixed;
    bottom: 0;
    left: 0;
    font-size: 7pt; }
  .footer__contact-block {
    width: 100%;
    margin: 1rem 0;
    text-align: center; } }

@media print {
  .catalog .car {
    width: 100% !important;
    page-break-after: always; }
    .catalog .car::before, .catalog .car::after {
      display: table;
      content: ''; }
    .catalog .car::after {
      clear: both; }
  .catalog .car__heading {
    text-align: center; }
  .catalog .car__description {
    text-align: left; }
  .catalog .car__description-value {
    font-weight: bold; }
  .catalog .car__image {
    margin: 10pt 0; }
    .catalog .car__image img {
      margin: 0 auto; }
  .catalog .car__heading-text {
    font-size: 16pt; }
  .catalog .car__price {
    float: right;
    font-size: 18pt; } }

@media print {
  .detail h3 {
    margin-top: .75rem; }
  .detail .slideshow-car {
    position: relative; }
  .detail .slideshow__item img {
    margin: 0 auto; }
  .detail .car {
    width: 100% !important; }
  .detail .car__section {
    margin-bottom: .75rem; }
  .detail .car__heading,
  .detail .car__description {
    text-align: left; }
  .detail .car__image {
    margin: 10pt 0; }
  .detail .car__price-content {
    margin: 20pt 0;
    text-align: center; }
    .detail .car__price-content strong {
      margin-left: 20pt;
      font-size: 24pt; }
  .detail .car__vendor-location {
    position: absolute;
    top: 20px;
    left: 50%;
    z-index: 6;
    transform: translateX(-50%); }
  .detail .car__vendor {
    width: 100%;
    margin-top: 30pt;
    page-break-after: always; }
    .detail .car__vendor::before, .detail .car__vendor::after {
      display: table;
      content: ''; }
    .detail .car__vendor::after {
      clear: both; }
  .detail .car__vendor-image,
  .detail .car__vendor-data {
    float: left; }
  .detail .car__vendor-image {
    max-width: 150pt;
    margin-right: 20pt; }
  .detail .car__description-title,
  .detail .car__description-value {
    width: 49%;
    display: inline-block;
    border-bottom: 1px solid #999; }
  .detail .car__claim {
    font-size: 12pt;
    font-style: italic; }
  .detail__price {
    width: 49%;
    display: inline-block; }
    .detail__price.-bordered {
      border-bottom: 1px solid #999; } }

@media print {
  .section__logo {
    display: none; } }
