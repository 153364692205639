// Mixin
//
//      file: base/mixin.scss
//
// Sass mixins
//
// styleguide 2.2

// Selection
//
// **`$color`**: selections text color
//
// **`$background`**: selections background color
//
// **`use`**: @include selection(color, background);
//
// <p class="ciu_embed" data-feature="css-selection" data-periods="future_1,current,past_1,past_2,past_3">
//     <a href="http://caniuse.com/#feat=css-selection">Can I Use css-selection?</a> Data on support for the css-selection feature across the major browsers from caniuse.com.
// </p>
//
// styleguide 2.2.1
@mixin selection(
    $color,
    $background
) {
    $error-message: 'Arguments are required, it needs to be a colors. Mixin -> selection(color !required, background !required)';

    @if type-of($color) == color and type-of($background) == color {
        ::-moz-selection {background: $background; color: $color;}
        ::selection {background: $background; color: $color;}
    } @else {
        @error #{$error-message};
    }
}

// Placeholder
//
// **`$color`**: placeholder text color
//
// **`use`**: @include placeholder(color);
//
// <p class="ciu_embed" data-feature="css-placeholder" data-periods="future_1,current,past_1,past_2,past_3">
//     <a href="http://caniuse.com/#feat=css-placeholder">Can I Use css-placeholder?</a> Data on support for the css-placeholder feature across the major browsers from caniuse.com.
// </p>
//
// styleguide 2.2.2
@mixin placeholder($color) {
    $error-message: 'Argument is required, it needs to be a color. Mixin -> placeholder(color !required)';

    @if type-of($color) == color {
        ::-moz-placeholder {
            opacity: 1;
            color: $color;
        }

        :-ms-input-placeholder {
            opacity: 1;
            color: $color;
        }

        ::-webkit-input-placeholder {
            opacity: 1;
            color: $color;
        }
    } @else {
        @error #{$error-message};
    }
}

// -Webkit scrollbar
//
// **`$width`**: scrollbar width
//
// **`$min-height`**: minimal scrollbar height
//
// **`$scrollbar_background`**: scrollbar track background color
//
// **`$thumb-background`**: scrollbar thumb background color
//
// **`use`**: @include scrollbar(width, min-height, track-background, thumb-background);
//
// styleguide 2.2.3
@mixin scrollbar(
    $width,
    $min-height,
    $track-background: $base_background,
    $thumb-background: $base_color
) {
    $error-message: 'Arguments are required. Mixin -> scrollbar($width !required, $min-height !required, $track-background !optional, $thumb-background !optional)';

    @if type-of($width) == number
    and type-of($min-height) == number
    and type-of($track-background) == color
    and type-of($thumb-background) == color {
        ::-webkit-scrollbar {width: $width;}
        ::-webkit-scrollbar-button {
            width: 0;
            height: 0;
        }

        ::-webkit-scrollbar-track {background-color: $track-background;}
        ::-webkit-scrollbar-corner {background: none;}

        ::-webkit-scrollbar-thumb {
            min-height: $min-height;
            background-color: $thumb-background;
            @if $base_border-radius > 0 {border-radius: $base_border-radius;}

            &:window-inactive {background-color: darken($track-background,10%);}
        }
    } @else {
        @error #{$error-message};
    }
}

// Display
//
// **`use`**: @include hidden;
//
// **`use`**: @include disabled;
//
// styleguide 2.2.4
@mixin hidden() {
    display: none !important;
    visibility: hidden;
}

@mixin disabled() {
    pointer-events: none;
    cursor: not-allowed;
}

// Visibility
//
// **`use`**: @include visually-hidden;
//
// **`use`**: @include visually-hidden-focusable;
//
// styleguide 2.2.5
@mixin visually-hidden() {
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: none;
}

@mixin visually-hidden-focusable() {
    &:focus,
    &:active {
        width: auto;
        height: auto;
        margin: 0;
        position: static;
        overflow: visible;
        clip: auto;
    }
}

// Clearfix
//
// **`use`**: @include clearfix;
//
// styleguide 2.2.6
@mixin clearfix() {
    &::before,
    &::after {
        display: table;
        content: '';
    }

    &::after {clear: both;}
}

// Background
//
// **`$image`**: background image file
//
// **`$background`**: background color, default: transparent
//
// **`$repeat`**: background repeat, default: no-repeat
//
// **`$horizontal`**: background horizontal position, default: center
//
// **`$vertical`**: background vertical position, default: center
//
// **`use`**: @include background-image('image.png');
//
// **`use`**: @include background-image('image.png',#000,repeat-x,left,top);
//
// styleguide 2.2.7
@mixin background-image(
    $image,
    $background: transparent,
    $repeat: no-repeat,
    $horizontal: center,
    $vertical: center
) {
    $error-message: 'First argument is required. Mixin -> background-image(image !required, background, repeat, horizontal, vertical)';

    background-color: $background;
    background-image: url('#{$base_image-path}/#{$image}');
    background-repeat: $repeat;
    background-position: $horizontal $vertical;
}

// Opacity
//
// **`$value`**: opacity value
//
// **`use`**: @include opacity(.5);
//
// styleguide 2.2.8
@mixin opacity($value) {
    $error-message: 'First argument is required. Value must be number and between 0 and 100. Mixin -> opacity(value !required)';

    @if type-of($value) == number
    and $value <= 1 {
        $MS_value: ($value * 100);
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$MS_value})";
        opacity: $value;
    } @else if $value > 1
    and $value <= 100 {
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$value})";
        opacity: $value / 100;
    } @else {
        @error #{$error-message};
    }
}

// Text
//
// **`use`**: @include hide-text;
//
// **`use`**: @include text-truncate;
//
// **`use`**: @include wrap-text;
//
// styleguide 2.2.9
@mixin hide-text() {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

@mixin text-truncate() {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@mixin wrap-text() {
    word-break: break-word;
    hyphens: auto;
}

// Retina
//
// **`use`**: @include media-retina {content};
//
// styleguide 2.2.10
@mixin media-retina {
    @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
        @content;
    }
}

// Fix
//
// Fix for vw, vh, vmin, vmax on iOS 7.
// http://caniuse.com/#feat=viewport-units
//
// **`src`**: https://gist.github.com/BenMorel/e9e34c08360ebbbd0634
//
// This fix works by replacing viewport units with px values on known screen sizes.
// iPhone 6 and 6 Plus cannot run iOS 7, so are not targeted by this fix.
// Target devices running iOS 8+ will incidentally execute the media query,
// but this will still produce the expected result; so this is not a problem.
//
// As an example, replace:
//
//   **height: 50vh;**
//
//   **font-size: 5vmin;**
//
// with:
//
//   **@include viewport-unit(height, 50vh);**
//
//   **@include viewport-unit(font-size, 5vmin);**
//
// styleguide 2.2.11
@mixin viewport-unit($property, $value) {
    #{$property}: $value;

    $unit: unit($value);

    @if (index((vw, vh, vmin, vmax), $unit) != null) {
        $devices: (
            (768px, 1024px), // iPad (all versions)
            (320px, 480px),  // iPhone 4
            (320px, 568px)   // iPhone 5, 5C, 5S
        );

        @each $device in $devices {
            $device-width: nth($device, 1);
            $device-height: nth($device, 2);

            $device-query: 'only screen and (-webkit-min-device-pixel-ratio: 1)';
            $device-query: '#{$device-query} and (device-width: #{$device-width})';
            $device-query: '#{$device-query} and (device-height: #{$device-height})';

            $percent: $value / ($value * 0 + 1); // see https://github.com/sass/sass/issues/533

            $percent-width: $device-width * $percent / 100;
            $percent-height: $device-height * $percent / 100;

            @if ($unit == vmin or $unit == vmax) {
                @media #{$device-query} {
                  #{$property}: if($unit == vmin, $percent-width, $percent-height);
                }
            } @else {
                @media #{$device-query} and (orientation: portrait) {
                    #{$property}: if($unit == vw, $percent-width, $percent-height);
                }

                @media #{$device-query} and (orientation: landscape) {
                    #{$property}: if($unit == vw, $percent-height, $percent-width);
                }
            }
        }
    }
}
